<template>
  <div>
    <div class="logo-box">
      <img class="logo" src="../assets/xbc-logo.jpg" alt="">
      <div class="text">小包车服务</div>
    </div>
    <button class="btn" @click="getWechatScheme">点击唤醒小程序</button>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'

const getWechatScheme = () => {
  window.location.href = `weixin://dl/business/?appid=wx5e08cbadd2a72459&path=pages/index/index&query=&env_version=release`
}

onMounted(() => {
  getWechatScheme()
})
</script>

<style scoped>
  .logo-box {
    margin-top: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .logo {
    width: 100px;
    height: 100px;
    border-radius: 18px;
  }
  .text {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  .btn {
    border: 0;
    background-color: #0061bd;
    font-size: 16px;
    font-weight: bold;
    padding: 15px 20px;
    color: #fff;
    border-radius: 2px;
    margin-top: 20%;
  }
</style>
